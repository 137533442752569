<template>
  <v-app>
    <v-content>
      <div
        style="
          margin-top: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <div style="display: flex; justify-content: center">
          <img src="/logo2.jpg" style="width: 300px" />
        </div>
        <div class="mt-10" style="margin-top: 30px; max-width: 520px">
         
          <v-card style="padding-top: 40px; margin: 10px" v-if="transaction" class="pl-3" >
            
            <h3 class="text-center mb-4">
              {{status}}
            </h3>
            <v-row>

              <v-col cols="6">
                <b>NIT:</b>
              </v-col>
              <v-col cols="6">
                900649069
              </v-col>
              <v-col cols="6">
                <b>Razón Social:</b>
              </v-col>
              <v-col cols="6">
                Inversiones Serprogreso S.A.S.
              </v-col>

              <v-col cols="6">
                <b>Fecha transacción</b>
              </v-col>
              <v-col cols="6">
                {{$filters.date(transaction.date_transaction, 'MMMM D YYYY, h:mm:ss a')}}
              </v-col>

       

              <v-col cols="6">
                <b>Monto total:</b>
              </v-col>
              <v-col cols="6">
                {{$filters.currency(transaction.data_transaction.total)}}
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12">
                <b>Información adicional:</b>
              </v-col>

              <v-col cols="6">
                <b>Banco:</b>
              </v-col>
              <v-col cols="6">
                {{transaction.data_transaction.banco.name}}
              </v-col>

              <v-col cols="6">
                <b>CUS:</b>
              </v-col>
              <v-col cols="6">
                {{transaction.cus}}
              </v-col>

              <v-col cols="6">
                <b>No. de transacción:</b>
              </v-col>
              <v-col cols="6">
                {{transaction.id}}
              </v-col>
              <v-col cols="6">
                <b>Tipo de transacción</b>
              </v-col>
              <v-col cols="6">
                Pago de crédito
              </v-col>
            </v-row>
            <!--v-list-item class="d-flex flex-column" v-if="credit">
              <div class="d-flex align-center">
                <img
                  src="https://app.serprogreso.com/logopse.png"
                  style="width: 64px; padding-right: 5px"
                />
                <span
                  >Su pago será procesado a través del Proveedor de Servicios
                  Electrónicos PSE</span
                >
              </div>
              <v-btn color="primary" :disabled="!isValueCorrect" @click="step = 2">Pagar con PSE</v-btn>
            </v-list-item-->

            
            
          </v-card>
        </div>

        <div style="display: flex; justify-content: center" v-if="failed" >
          <v-btn to="/payments" class="text-h5" style="cursor: pointer"
             >Volver a intentar</v-btn>
        </div>

        <div style="display: flex; justify-content: center">
          <v-btn to="/" class="text-h5" style="cursor: pointer"
             color="primary">Volver al inicio</v-btn>
        </div>
      </div>
    </v-content>
  </v-app>
</template>

<script>


export default {
  components: {  },
  name: "PaymentClient",
  data: () => ({
    credit: null,
    transaction: null
  }),
  computed: {
    status(){
      if(this.transaction.status == 'NOT_AUTHORIZED'){
        return 'Transacción Rechazada'
      }

      if(this.transaction.status == 'FAILED,'){
        return 'Transacción Fallida'
      }

      if(this.transaction.status == 'OK'){
        return 'Pago Aprobado'
      }

      if(this.transaction.status == 'PENDING'){
        return 'Transacción Pendiente. Por favor verificar si el débito fue realizado en el Banco'
      }
      

      if(this.transaction.status == 'FAIL_EXCEEDEDLIMIT'){
        return 'El monto de la transacción excede los límites establecidos en PSE para la empresa, por favor comuníquese con la empresa'
      }

      if(this.transaction.status == 'FAIL_BANKUNREACHEABLE'){
        return 'La entidad financiera no puede ser contactada para iniciar la transacción, por favor seleccione otra o intente más tarde'
      }



      if(this.transaction.status == 'FAIL_ENTITYNOTEXISTSORDISABLED' 
        || this.transaction.status == 'FAIL_BANKNOTEXISTSORDISABLED'
        || this.transaction.status == 'FAIL_SERVICENOTEXISTSORNOTCONFIGURED'
        || this.transaction.status == 'FAIL_INVALIDAMOUNTORVATAMOUNT'
        || this.transaction.status == 'FAIL_INVALIDAMOUNT'
        || this.transaction.status == 'FAIL_INVALIDSOLICITDATE'
        || this.transaction.status == 'FAIL_CANNOTGETCURRENTCYCLE'
        || this.transaction.status == 'FAIL_ACCESSDENIED'
        || this.transaction.status == 'FAIL_TRANSACTIONNOTALLOWED'
        || this.transaction.status == 'FAIL_INVALIDPARAMETERS'
        || this.transaction.status == 'FAIL_GENERICERROR'
        || this.transaction.status == 'FAIL_NOTCONFIRMEDBYBANK'
        || this.transaction.status == 'FAIL_TIMEOUT'
        || this.transaction.status == 'FAILED'
          ){
        return 'Transacción Fallida, por favor intente más tarde o comuníquese con la empresa'
      }
      return 'Transacción Fallida, por favor intente más tarde o comuníquese con la empresa'
      
    },
    pending() {
      return this.transaction.status == 'PENDING'
    },
    failed(){
      if(this.transaction  && (this.transaction.status == 'FAIL_ENTITYNOTEXISTSORDISABLED' 
        || this.transaction.status == 'FAIL_EXCEEDEDLIMIT'
        || this.transaction.status == 'FAIL_BANKUNREACHEABLE'
        || this.transaction.status == 'FAIL_BANKNOTEXISTSORDISABLED'
        || this.transaction.status == 'FAIL_SERVICENOTEXISTSORNOTCONFIGURED'
        || this.transaction.status == 'FAIL_INVALIDAMOUNTORVATAMOUNT'
        || this.transaction.status == 'FAIL_INVALIDAMOUNT'
        || this.transaction.status == 'FAIL_INVALIDSOLICITDATE'
        || this.transaction.status == 'FAIL_CANNOTGETCURRENTCYCLE'
        || this.transaction.status == 'FAIL_ACCESSDENIED'
        || this.transaction.status == 'FAIL_TRANSACTIONNOTALLOWED'
        || this.transaction.status == 'FAIL_INVALIDPARAMETERS'
        || this.transaction.status == 'FAIL_GENERICERROR'
        || this.transaction.status == 'FAIL_NOTCONFIRMEDBYBANK'
        || this.transaction.status == 'FAIL_TIMEOUT'
        || this.transaction.status == 'FAILED'
        || this.transaction.status == 'NOT_AUTHORIZED')
          ){
        return true;
      }
      return false;
    }
  },
  created() {
  



  },

  mounted() {
      this.load();
  },
  methods: {
    load() {
        this.$http.post("https://pse3.serprogreso.com/api/v1/pse/transaction", { identifier: this.$route.params.id }, this, true, true).then(response => {
            
            if(response.success){
              this.transaction = response.item;
              if(this.transaction.status == 'OK' || this.transaction.status == 'PENDING'){
                this.$store.commit('setTransaction', null);
              }else{                
                this.$store.commit('setTransaction', this.transaction);
              }
            }
        })
    },
  },
};
/*

“En este momento su # <Numero de Referencia o Factura>
presenta un proceso de pago cuya transacción se encuentra PENDIENTE de recibir confirmación por
parte de su entidad financiera, por favor espere unos minutos y vuelva a consultar más tarde para
verificar si su pago fue confirmado de forma exitosa. Si desea mayor información sobre el estado
actual de su operación puede comunicarse a nuestras líneas de atención al cliente 57-1- 9999999 o
enviar un correo electronico a email@email.com.co y preguntar por el estado de la transacción:
<#CUS>”

Por otra parte el usuario que efectué una operación ya finalizada con estado APROBADA, se debe
informar de ésta situación mediante un mensaje como el siguiente: “En este momento su #
<Numero de Referencia o Factura> ha finalizado su proceso de pago y cuya transacción se encuentra
APROBADA en su entidad financiera. Si desea mayor información sobre el estado de su operación puede
comunicarse a nuestras líneas de atención al cliente 57-1-9999999 o enviar un correo electronico a
email@email.com.co y preguntar por el estado de la transacción: <#CUS>”. No se puede permitir
realizar un nuevo proceso de pago asociado a esta transacción, en caso de no permitir iniciar el pago
este mensaje no es requerido.

“En este
momento presenta un proceso de pago PENDIENTE de recibir confirmación por parte de la entidad
financiera, por favor espere unos minutos y vuelva a consultar más tarde para verificar si su pago
fue confirmado de forma exitosa. Puede continuar con esta transacción pero existe la posibilidad
que el pago sea aplicado dos veces. Si desea mayor información sobre el estado actual de su
operación puede comunicarse a nuestras líneas de atención al cliente 57-1-9999999 o enviar un
correo electronico a email@email.com.co y pregunte por el estado de la transacción: <#CUS>”.


*/
</script>
<style scoped>
.v-list-item--active {
  color: #a10505 !important;
}
</style>
